<template>
  <!-- APPONE -->
  <v-container class="con1">
    <v-row>
      <v-col
        cols="6"
        md="3"
      >
        <v-img
          class="elevation-3"
          data-aos="flip-up"
          data-aos-duration="1000"
          contain
          src="@/assets/wplunkket.jpg"
          color="grey lighten-1"
          tile
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-img
          class="elevation-3"
          data-aos-duration="1000"
          data-aos="flip-up"
          contain
          src="@/assets/thelockkeeper.jpg"
          color="grey lighten-1"
          tile
        />
      </v-col>      <v-col
        cols="6"
        md="3"
      >
        <v-img
          class="elevation-3"
          data-aos="flip-up"
          data-aos-duration="1000"
          contain
          src="@/assets/mulliganandhaines.jpg"
          color="grey lighten-1"
          tile
        />
      </v-col>      <v-col
        cols="6"
        md="3"
      >
        <v-img
          class="elevation-3"
          data-aos="flip-up"
          data-aos-duration="1000"
          contain
          src="@/assets/mooneys.jpg"
          color="grey lighten-1"
          tile
        />
      </v-col>
    </v-row>
    <v-row class=" d-flex justify-content-center">
      <v-col
        cols="6"
        md="3"
      >
        <v-img
          class="elevation-3"
          data-aos="flip-up"
          data-aos-duration="1000"
          contain
          src="@/assets/mulliganhotelNEW.jpg"
          color="grey lighten-1"
          tile
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-img
          class="elevation-3"
          data-aos="flip-up"
          contain
          src="@/assets/ablhotelNEW.jpg"
          data-aos-duration="1000"
          color="grey lighten-1"
          tile
        />
      </v-col>      <v-col
        cols="6"
        md="3"
      >
        <v-img
          class="elevation-3"
          data-aos="flip-up"
          contain
          src="@/assets/thesackville.jpg"
          data-aos-duration="1000"
          color="grey lighten-1"
          tile
        />
      </v-col>
    </v-row>
    <br>
  </v-container>
</template>

<script>
  export default {
    name: 'Brandstwo',

    data () {
      return {
        appones: [
          {
            src: require('@/assets/m1shad.png'),
          },
          {
            src: require('@/assets/m1shad.png'),
          },
          {
            src: require('@/assets/m1shad.png'),
          },
          {
            src: require('@/assets/m1shad.png'),
          },
        ],
      }
    },
  }
</script>
